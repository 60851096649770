import React from "react";
import { PortableText } from "@portabletext/react";

const PortableTextBlock = ({ blocks }) => (
  <div className="portable">
    <PortableText value={blocks} />
  </div>
);

export default PortableTextBlock;
